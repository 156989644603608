import React from "react";
import { useQuery } from "react-query";
import { getDestinatarios } from "api";
import { IonList, IonItem, IonLabel } from "@ionic/react";
import CustomInput from "./CustomInput";
const Destinatario = ({onChange, value, tipo=null}) => {
	const [focused, setFocused] = React.useState(false);
	const [searchString, setSearchString] = React.useState("");
	const {data} = useQuery("destinatarios",getDestinatarios);
	const filtered = React.useMemo(()=>(data || []).filter(d=> (tipo ? d?.tipo==tipo : true) && d.nombre?.includes(searchString)), [data, searchString, tipo]);
	const onKey = (e) => {
		e.key==="Enter" ? setFocused(false) :  setFocused(true)
	}
	const onSearchChange = (e) => {
		
		onChange(e.target.value)
		setSearchString(e.target.value)
	}
	return <div>
		<CustomInput label="Para" value={value} onIonInput={onSearchChange} onKeyDown={onKey} onFocus={()=>setFocused(true)} onBlur={()=>setTimeout(()=>setFocused(false),100)}  placeholder="Fuente de ingreso"></CustomInput>
		{focused && <IonList>
			{filtered.map(o => <IonItem button onClick={()=>(onChange(o.nombre),setFocused(false))}>
				<IonLabel className="ion-text-wrap">
					<h2>{o.nombre}</h2>
				</IonLabel>
			</IonItem>)}	
    </IonList>}
	</div>
}
export default Destinatario;