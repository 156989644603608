import moment from "moment";
export default function Currency({amount}){
	return <>
		{format(amount)}
	</>;
}
export function format(num) {
	if(typeof num === "number"){
	}
	else if(typeof num ==="string" && isFinite(num)) 
		num = Number(num);
	else {
		return num;
	}

	var p = num.toFixed(2).split(".");
	return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
		return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
	}, "") + "." + p[1];
}