import React from "react";
import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { Redirect, Route } from 'react-router-dom';
import CustomInput from "components/CustomInput";
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonInput,
	IonButton,
	IonSpinner,
	IonChip,
	IonButtons,
	IonBackButton
} from '@ionic/react';
import Logo from "components/Logo";
import './Home.css';
import {login, getUser, init, logout} from "api";
import {useMutation} from "react-query";
import useGlobalContext from "../contexts/GlobalContext";



const Login: React.FC = () => {

	const { setUser, user } = useGlobalContext()
  const [usuario, setUsuario] = useState<any>(user);

 


	console.log({user})
	if(!user){
    return <Redirect to="/login" />;
	}

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perfil</IonTitle>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/app/operaciones" />
						
					</IonButtons>
					
        </IonToolbar>
      </IonHeader>
      
      <IonContent style={{textAlign:"center"}} fullscreen>

				<div style={{background:"#FFBD50", height:"100%"}}>
					<div style={{background:"#0061B0", width:"100%", height: 30}} />
					<div style={{padding:20, textAlign:"center"}}>
						<CustomInput disabled style={{marginBottom:20}} value={usuario.nombre} placeholder="Nombre" onIonChange={e => setUsuario(u=>({...u, nombre: e.detail.value || ""}))}></CustomInput>
						<CustomInput disabled style={{marginBottom:20}} value={usuario.correo} placeholder="Correo" onIonChange={e => setUsuario(u=>({...u, correo: e.detail.value || ""}))}></CustomInput>

						<IonButton expand="block" onClick={() => (logout(), setUser(null))}>Cerrar sesión</IonButton>

        </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
