import React from 'react';
import { IonSlides, IonSlide, IonContent, IonPage, IonButton } from '@ionic/react';
import t1 from "img/tutorial/1.png";
import t2 from "img/tutorial/2.png";
import t3 from "img/tutorial/3.png";
import t4 from "img/tutorial/4.png";
import t5 from "img/tutorial/5.png";
import t6 from "img/tutorial/6.png";
import t7 from "img/tutorial/7.png";
import t8 from "img/tutorial/8.png";
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
// Optional parameters to pass to the swiper instance.
// See https://swiperjs.com/swiper-api for valid options.
const slideOpts = {
  initialSlide: 0,
  speed: 400
};
const tutoriales = {
	home:{
		slides:[
			{img:t1},
			{img:t2},
			{img:t3},
			{img:t4},
		],
		goTo:"/app/operaciones"
	},
	general:{
		slides:[
			{img:t1},
			{img:t2},
			{img:t3},
			{img:t4},
			{img:t8},
			{img:t5},
			{img:t6},
			{img:t7}
		],
		goTo:"/app/operaciones"
	},
	operacion:{
		slides:[

			{img:t8},
		],
		goTo:"/app/operacion/ingreso"

	},
	reporte:[
		{img:t5},
		{img:t6},
	],
}
const Tutorial: React.FC<any> = () => {
	const {tipo} = useParams<any>();
	const history = useHistory()

	const tutorial = React.useMemo(()=>tutoriales[tipo],[tipo]);
	const [isEnd, setIsEnd] = React.useState(()=>tutorial?.length===1);

	return  <IonPage id="home-page">
  <IonContent>
    <IonSlides onIonSlideDidChange={(e:any)=>setIsEnd(e?.target?.swiper?.isEnd)} style={{background:"#FEF9EE"}} pager={true} options={slideOpts}>
			{tutorial.slides.map(slide=><IonSlide>
				<IonButton style={{position:"absolute", right:10, top:10, zIndex:1}} onClick={()=>history.replace(tutorial.goTo)} fill="clear"  size="large">Saltar</IonButton>

				<div style={{display:"flex", flexDirection:"column", height:"100%", padding:10}}>
					{/* <h1>{slide.texto}</h1> */}
					<img src={slide.img} style={{marginTop:40}} />
					{isEnd && <IonButton onClick={()=>history.replace(tutorial.goTo)} fill="clear" style={{marginTop:10}} size="large">Cerrar</IonButton>}
				</div>
      </IonSlide>)}
      
      
    </IonSlides>
  </IonContent>
	</IonPage>
};
export default Tutorial