import React from "react";
import ViajeItem from '../components/Viajes/ViajeItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { ViajeItemProp } from '../interfaces/Viajes';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonButton,
	IonButtons,
	IonBackButton,
	IonFooter,
	useIonAlert,
	IonInput,
	IonDatetime,
	IonItem,
	IonSpinner,
	IonIcon,
	IonLabel,
	IonText,
	useIonModal
} from '@ionic/react';
import {getDestinatarios, postOperacion} from "api";
import GlobalContext from "contexts/GlobalContext";
import './Home.css';
import { isPlatform } from "@ionic/core";
import moment from "moment";
import { useParams } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Address, Date, Error } from 'components/Core';
import styled from "styled-components";
import {useQuery, useMutation} from "react-query";
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera';
import Tutorial from "components/TutorialModal";
import { helpCircleOutline } from "ionicons/icons";
import Destinatario from "components/Destinatario";
const Row = styled.div`
	margin-bottom: 20px;
`

const Home: React.FC<any> = () => {


	const history = useHistory();
	const {tipo} = useParams<any>();
  const [gasto, setGasto] = useState<any>({tipo, fecha:moment().toISOString(), recurrencia:false});
  const [file, setFile] = useState<any>();
  const [localError, setError] = useState<any>(null);
	const {mutateAsync, status, error} = useMutation(postOperacion, {onSuccess:()=>{
		// dismiss();
		history.goBack();
	}})

	const closeTutorial = () =>{
		dismissTutorial()
	}
	const [present, dismissTutorial] = useIonModal(Tutorial, {
    tipo:"operacion",
		close: closeTutorial
  });
	const guardar = () => {
		setError(null);
		if(!gasto.destinatario){
			setError({errmsg:"El campo destinatario no puede estar vacio"});
			return
		}

		mutateAsync({data:gasto, file})
	}

	console.log({web:isPlatform("cordova")})

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
				<IonButtons slot="end">
						<IonIcon onClick={()=>present()} icon={helpCircleOutline} style={{fontSize:"2.5em"}}></IonIcon>

						<IonButton onClick={() => (history.goBack())}>Cancelar</IonButton> 
					</IonButtons>
          <IonTitle>Nuevo {tipo}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
				
				<Row>
					<Destinatario value={gasto?.destinatario} tipo={tipo} onChange={v => setGasto(u=>({...u, destinatario: v})) } />
				</Row>
				<Row>
					<CustomInput label="Cantidad" value={gasto.cantidad} placeholder="xxx.xx" onIonChange={e => setGasto(u=>({...u, cantidad: e.detail.value || ""}))}></CustomInput>
				</Row>
				<Row>
					<CustomInput label="Notas" value={gasto.notas} placeholder="Notas" onIonChange={e => setGasto(u=>({...u, notas: e.detail.value || ""}))}></CustomInput>				
				</Row>
				{isPlatform("cordova") && <Row>
					<File onFile={(f)=>setFile(f)} />
				</Row>}
				<Row>
					<Periodo value={gasto.recurrencia} onChange={(v)=>setGasto(u=>({...u, recurrencia: v})) } />
				</Row>
				<Row>
					<MyDate value={gasto?.fecha} onChange={v => setGasto(u=>({...u, fecha: v}))} />
				</Row>	
        
      </IonContent>
			<IonFooter style={{background:"white"}} className="ion-no-border">
				{/* <IonToolbar> */}
					{status==="error" && <Error error={error} />}
					{localError && <Error error={localError} />}

					{status!=="loading" && <IonButton expand="block" onClick={guardar}>Guardar</IonButton> }
					{status==="loading" && <IonSpinner />}
				{/* </IonToolbar> */}
			</IonFooter>
    </IonPage>
  );
};
const options = [
	{value:false, label:"1 vez"},
	{value:"semanal", label:"Semanal"},
	{value:"quincenal", label:"Quincenal"},
	{value:"mensual", label:"Mensual"},
]
const Periodo = ({onChange, value}) => {
	return <div>
		<div>
			<CustomLabel>Periodo</CustomLabel>
		</div>
		<Select value={value} onChange={onChange} options={options} />
	</div>;
}
const Select: React.FC<any> = ({options, onChange, value}) => {
	return <div style={{display:"flex", justifyContent:"space-between" }}>
		{options.map(o=><Option {...o} onChange={onChange} selected={value===o.value} />)}
	</div>
}
const StyledOption = styled.div<any>`
	padding:10px 10px;
	border-radius:10px;
	background: ${props=>props.selected ? "var(--ion-color-primary)" : "var(--ion-color-secondary)"};
	color: ${props=>props.selected ? "var(--ion-color-secondary)" : "var(--ion-color-primary)"};
`
const Option: React.FC<any> = ({label, value, onChange, selected}) => {
	return <>
		<StyledOption selected={selected} onClick={()=>onChange(value)}>{label}</StyledOption>
	</>
}
const CustomInput: React.FC<any> = ({label, ...rest}) => {
	return <>
		<CustomLabel>{label}</CustomLabel>
		<IonInput {...rest} style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}}></IonInput>
	</>
}
const CustomLabel: React.FC<any> = ({children, ...rest}) => {
	return <>
		<IonText {...rest}>{children}</IonText>
	</>
}


const MyDate = ({onChange, value}) => {
	const [isOpen, setOpen] = React.useState(false)
	return <>
		<CustomLabel>Seleccionar fecha</CustomLabel>
		<IonDatetime value={value} style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}} onIonChange={ev => onChange(ev.detail.value!)} placeholder="Seleccionar fecha" /> 
	</>
}
const File = ({onFile}) => {

	const subirEvidencia = async () => {
		const options: CameraOptions = {
			quality: 100,
			sourceType:1,
		}
		
		const pic = await Camera.getPicture(options);
		onFile(pic);
	}
	

	return <div>
		<CustomLabel>Añadir imagen</CustomLabel>
		<div>
			<IonButton  onClick={subirEvidencia}>Seleccionar</IonButton>
		</div>
	</div>
}

export default Home;
const Title = styled.div`
	font-size:1.3em;
`;