// import ViajeItem from '../components/Viajes/ViajeItem';
import React, { useState } from 'react';
// import { ViajeItemProp } from '../interfaces/Viajes';
import { getOperaciones, logout, getMaaser, updateUser, getUser } from '../api';
import {  IonSegment, IonSegmentButton, useIonModal } from '@ionic/react';
import Operacion from "./Operacion";
import DetalleOperacion from "./DetalleOperacion";
import { useHistory } from 'react-router';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
	IonButtons,
	IonBackButton,
  useIonViewWillEnter,
	useIonViewDidEnter,
	IonText,
	IonSpinner
} from '@ionic/react';
import './Home.css';
import {Currency, Date} from "components/Core";
import {
  IonItem,
  IonLabel,
  IonNote,
	IonButton,
	IonModal
  } from '@ionic/react';
import {useQuery} from "react-query";
import moment from "moment";
import donativoImg from "img/donativo.png";
import ingresoImg from "img/ingreso.png";
import egresoImg from "img/egreso.png";
import Logo from "components/Logo";
import styled from 'styled-components';
import Tutorial from 'components/Tutorial';
import useGlobalContext from "../contexts/GlobalContext";

const imgMap = {
	donativo: donativoImg,
	ingreso: ingresoImg,
	egreso: egresoImg,
}
const MaaserBox = styled.div`
-webkit-box-shadow: 5px 6px 11px -4px #005EC0; 
box-shadow: 5px 6px 11px -4px #005EC0;
margin-bottom:20px;
`
const Viajes: React.FC = () => {

	const history = useHistory();

	const {data: user, status:statusUser} = useQuery("user", getUser)
	const {data: operaciones, status, refetch} = useQuery("operaciones", getOperaciones)
	const {data: maaser, status:statusMaaser} = useQuery("maaser", getMaaser)
	
  // const [operaciones, setOperaciones] = useState<any[]>([]);
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [openedDetalle, setOpenedDetalle] = useState<boolean>(false);
  const [selectedOp, setSelectedOp] = useState<any>(null);
  const [newType, setNewType] = useState<string>("ingreso");
  const [filter, setFilter] = useState<any>("todo");
	
	const sortedOp = React.useMemo(()=>(operaciones || []).sort((a,b)=>moment(a.fecha).diff(moment(b.fecha))),[operaciones])
	const filteredOp = React.useMemo(()=>filter==="todo" ? sortedOp : sortedOp.filter(o=>o.tipo===filter), [sortedOp, filter])


  const refresh = async (e: CustomEvent) => {
		await refetch();
		e.detail.complete();

  };

	const closeTutorial = () =>{
		// dismiss()
	}
	
	// const [present, dismiss] = useIonModal(Tutorial, {
  //   tipo:"home",
	// 	close: closeTutorial
  // });

	React.useEffect(()=>{
		console.log(statusUser)
		if(!user?.shown_tutorial && statusUser==="success"){
		
			// present();
			setTimeout(()=>{

					history.push("/tutorial/general")

			},200)
			updateUser({shown_tutorial:true}).then(u=>{
				// setUser(u)
			})
		}

	},[statusUser]);

	



  return (
    <IonPage id="home-page">
      <IonHeader style={{background:"white"}}>
			<Logo onClickTutorial={()=>history.push("/tutorial/home")} closeTutorial={()=>history.goBack()} />

        {/* <IonToolbar>

					<IonButtons slot="start">
					</IonButtons>
        </IonToolbar> */}
				<div style={{display:"flex", justifyContent:"center"}}>
					<MaaserBox style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", background:"#FFE2A3", padding:"20px 60px", borderRadius:10, marginTop:5}}>
						<IonText color="primary">Maaser total</IonText>
						<IonText color="primary" style={{fontSize:"2em", fontWeight:"bold"}}><Currency amount={maaser} /></IonText>
					</MaaserBox>
				</div>
				<div style={{display:"flex", justifyContent:"center"}}>
					<IonButton color="ingreso" onClick={()=>history.push("/operaciones/new/ingreso")}>+ Ingreso</IonButton>
					<IonButton color="egreso" onClick={()=>history.push("/operaciones/new/egreso")}>+ Egreso</IonButton>
					<IonButton color="donativo" onClick={()=>history.push("/operaciones/new/donativo")}>+ Donativo</IonButton>
				</div>
        <IonSegment  mode="md" class="md" onIonChange={e => setFilter(e.detail.value)} value={filter}>
          <IonSegmentButton value="todo">
            <IonLabel>Todo</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="ingreso">
            <IonLabel>Ingresos</IonLabel>
          </IonSegmentButton>
					<IonSegmentButton value="egreso">
            <IonLabel>Egresos</IonLabel>
          </IonSegmentButton>
					<IonSegmentButton value="donativo">
            <IonLabel>Donaciones</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonHeader>
      <IonContent fullscreen>
				<IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
				{status==="loading" && <IonSpinner />}
				
        <IonList>
          {(filteredOp || []).map(operacion => <OperacionItem onClick={o=>history.push(`/detalle-operacion/${o._id}`)} key={operacion.id} {...operacion} operacion={operacion} />)}
					
        </IonList>
      </IonContent>
			
			<IonModal onDidDismiss={(data)=>{
						setSelectedOp(null);
						setOpenedDetalle(false)
					}} isOpen={openedDetalle}>
        <IonContent>
					<DetalleOperacion id={selectedOp?._id} dismiss={(data)=>{
						setSelectedOp(null);
						setOpenedDetalle(false)
					}} />
				</IonContent>
      </IonModal>
			
    </IonPage>
  );
};

export default Viajes;

// import './MessageListItem.css';



const OperacionItem: React.FC<any> = ({ id, fecha, cantidad, titulo, destinatario, tipo, onClick, operacion }) => {
  return (
    <IonItem onClick={()=>onClick(operacion)} detail={false}>
      {/* <div slot="start" className="dot dot-unread"></div> */}
			<img slot="start" style={{width:30}} src={imgMap[tipo]} />
      <IonLabel className="ion-text-wrap">
				{/* <IonThumbnail slot="start">
          
        </IonThumbnail> */}
				<IonText color={tipo}><Date date={fecha} format="DD/MMM/YY" /></IonText>
        <IonText color={tipo}><h2>{destinatario?.nombre}</h2></IonText>
        
      </IonLabel>
			<p slot="end">
				<IonText color={tipo}><Currency amount={cantidad} /></IonText>
      </p>
    </IonItem>
  );
};
