import React from "react";
import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { Redirect, Route } from 'react-router-dom';
import CustomInput from "components/CustomInput";
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonInput,
	IonButton,
	IonSpinner,
	IonChip,
	useIonPicker
} from '@ionic/react';
import logo from "img/logo.png";
import { Link } from 'react-router-dom';

import './Home.css';
import {registro, getUser, init} from "api";
import {useMutation} from "react-query";
import useGlobalContext from "../contexts/GlobalContext";

interface Usuario {
  correo: string;
  password: string;
}

const Login: React.FC = () => {
	const {status, error, mutate:doLogin} = useMutation<any,any, any>(registro, {
		onSuccess:async s=>{
			init(s.token);
			const user = await getUser();
			setUser(user);
		}
	});
	const { setUser, user } = useGlobalContext()
  const [messages, setMessages] = useState<Message[]>([]);
  const [usuario, setUsuario] = useState<any>({correo:"",password:"", nombre:"", password2:""});
  const [present] = useIonPicker();

	const showPicker = () => {
		present({
			buttons: [
				{
					text: 'Confirmar',
					handler: (selected) => {
						setUsuario(u=>({...u, p_maaser: selected.maaser.value}))
					},
				},
			],
			columns: [
				{
					name: 'maaser',
					options: [
						{ text: 'Maaser (10%)', value: 10 },
						{ text: 'Jomesh (20%)', value: 20 },
					],
				},
			],
		})
	}
  useIonViewWillEnter(() => {
    const msgs = getMessages();
    setMessages(msgs);
  });



	if(user){
    return <Redirect to="/app/operaciones" />;
	}

  return (
    <IonPage id="home-page">
      
      <IonContent style={{textAlign:"center"}} fullscreen>

        <img src={logo} style={{marginTop:50, width:"60%"}} />
				<div style={{background:"#FFBD50", width:"100%", height: "80%", position:"absolute", bottom:0}}>
					<div style={{background:"#0061B0", width:"100%", height: 30}} />
					<div style={{padding:20, textAlign:"center"}}>
						<CustomInput style={{marginBottom:20}} value={usuario.nombre} placeholder="Nombre" onIonChange={e => setUsuario(u=>({...u, nombre: e.detail.value || ""}))}></CustomInput>
						<CustomInput style={{marginBottom:20}} value={usuario.p_maaser} onClick={showPicker} disabled placeholder="% de maaser" onIonChange={e => setUsuario(u=>({...u, p_maaser: e.detail.value || ""}))}></CustomInput>
						<CustomInput style={{marginBottom:20}} value={usuario.correo} placeholder="Correo" type="email" onIonChange={e => setUsuario(u=>({...u, correo: e.detail.value || ""}))}></CustomInput>
						<CustomInput style={{marginBottom:20}} value={usuario.password} placeholder="Contraseña" type="password" onIonChange={e => setUsuario(u=>({...u, password: e.detail.value || ""}))}></CustomInput>
						<CustomInput style={{marginBottom:20}} value={usuario.password2} placeholder="Verificar Contraseña" type="password" onIonChange={e => setUsuario(u=>({...u, password2: e.detail.value || ""}))}></CustomInput>
						<div>Al registrarte aceptas nuestro <a target="_blank" rel="noopener noreferrer" href="https://mimaaser.com.mx/avisoprivacidad.html">Aviso de privacidad</a></div>
						{status==="loading" && <IonSpinner />}
						{status==="error" && <IonChip color="danger">{error?.errmsg}</IonChip>}
						{status!=="loading" && <IonButton expand="block" style={{marginBottom:20}} onClick={()=>doLogin(usuario)}>Registro</IonButton>}
						<Link to="/login"><IonButton fill="clear">Ya tienes cuenta? Inicia sesión</IonButton></Link>
					</div>
					
				</div>
				
				

        
      </IonContent>
    </IonPage>
  );
};

export default Login;
