import React from "react";
import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { Redirect, Route } from 'react-router-dom';
import CustomInput from "components/CustomInput";
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonInput,
	IonButton,
	IonSpinner,
	IonChip,
	IonButtons, IonBackButton
} from '@ionic/react';
import logo from "img/logo.png";
import { Link } from 'react-router-dom';

import './Home.css';
import {login, getUser, init} from "api";
import {useMutation} from "react-query";
import useGlobalContext from "../contexts/GlobalContext";

interface Usuario {
  correo: string;
  password: string;
}

const Login: React.FC = () => {
	const {status, error, mutate:doLogin} = useMutation<any,any, any>(login, {
		onSuccess:async s=>{
			init(s.token);
			const user = await getUser();
			setUser(user);
		}
	});
	const { setUser, user, statusUser } = useGlobalContext()
  const [messages, setMessages] = useState<Message[]>([]);
  const [usuario, setUsuario] = useState<Usuario>({correo:"",password:""});

  useIonViewWillEnter(() => {
    const msgs = getMessages();
    setMessages(msgs);
  });


  return (
    <IonPage id="home-page">
      <IonHeader>
				<IonToolbar>
					<IonButtons>
						<IonBackButton defaultHref="/app/operaciones" />

					</IonButtons>
				</IonToolbar>
			</IonHeader>
      <IonContent style={{textAlign:"center"}} fullscreen>

        <img src={logo} style={{marginTop:150, width:"60%"}} />
				<div style={{background:"#FFBD50", width:"100%", height: "60%", position:"absolute", bottom:0}}>
					<div style={{background:"#0061B0", width:"100%", height: 30}} />
					<div style={{padding:20, textAlign:"center"}}>
					<h3>Dudas sobre halajot:</h3>
					<div>Mail: <a href="mailto:dudasmimaaser@gmail.com">dudasmimaaser@gmail.com</a></div>
					<div>Tel del Rabino: <a href="tel:55 6317 4939">55 6317 4939</a></div>
					
					<h3>Dudas o comentarios sobre el app:</h3>
					<div>Mail: <a href="mailto:mimaaserapp@gmail.com">mimaaserapp@gmail.com</a></div>

				</div>
					</div>
				
				

        
      </IonContent>
    </IonPage>
  );
};

export default Login;
