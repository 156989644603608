import React from "react";
import ViajeItem from '../components/Viajes/ViajeItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { ViajeItemProp } from '../interfaces/Viajes';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonButton,
	IonButtons,
	IonBackButton,
	IonFooter,
	useIonAlert,
	IonInput,
	IonDatetime,
	IonItem,
	IonSpinner,
	IonIcon,
	IonLabel,
	IonText,
	IonGrid, IonRow, IonCol,
} from '@ionic/react';
import domtoimage from 'dom-to-image';

import {getDestinatarios, getOperacion, removeOperacion, removeRecurrencia} from "api";
import GlobalContext from "contexts/GlobalContext";
import './Home.css';
import moment from "moment";
import { useParams } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Address, Date, Currency } from 'components/Core';
import styled from "styled-components";
import {useQuery, useMutation} from "react-query";
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';
import { checkmarkCircleOutline } from "ionicons/icons";
import check from "img/check.png";
const Row = styled(IonRow)`
	border-bottom: solid 1px #3563CA;
	padding: 10px 0px;
`
const Label = styled.div`
	color:var(--ion-color-primary);
	font-weight:bold;
	
`

const Value = styled(IonCol)`
	text-align:right
	
`

const Home: React.FC<any> = () => {


	const history = useHistory();
	const {id} = useParams<any>();
	const {data, status} = useQuery<any>(["operaciones", id],getOperacion)

	const {mutateAsync:removeOp, status:satusOp} = useMutation(removeOperacion, {onSuccess:()=>{
		history.goBack();
	}})
	const {mutateAsync:removeRec, status:statusRec} = useMutation(removeRecurrencia, {onSuccess:()=>{
		history.goBack();
	}})
	const imgRef = React.useRef<any>();
	const compartir = () => {
		console.log(imgRef);
		domtoimage.toPng(imgRef.current)
    .then(function (dataUrl) {
			console.log({dataUrl})
			var options = {
				// message: `Tipo: ${data.tipo}\nFecha: ${data.fecha}\nCantidad: ${data.cantidad}\nDestinatario: ${data.destinatario?.nombre}`, // fi. for email
				// subject: `Tipo: ${data.tipo}\nFecha: ${data.fecha}\nCantidad: ${data.cantidad}\nDestinatario: ${data.destinatario?.nombre}`, // fi. for email
				files: [dataUrl], // an array of filenames either locally or remotely
				// chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
				// appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
				// iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
			};
			
		
			SocialSharing.shareWithOptions(options)
    })
    .catch(function (error) {
        console.error('oops, something went wrong!', error);
    });
		
	
	}

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
				<IonButtons slot="start">
					<IonBackButton defaultHref="/app/operaciones" />

						{/* <IonButton onClick={() => (history.goBack())}>Cerrar</IonButton>  */}
					</IonButtons>
          <IonTitle style={{textTransform:"capitalize"}}>{data?.tipo}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
				<div style={{background: "#F6F8FE", height:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
					{/* <IonIcon style={{    width: "100%", fontSize: "5em", color: "#3563CA"}} icon={checkmarkCircleOutline}></IonIcon> */}
					<div ref={imgRef} style={{background: "#F6F8FE", padding:20, width:"100%", marginTop:20}}>
						<div style={{textAlign:"center"}}><img style={{   width: 60, color: "#3563CA", textAlign:"center"}} src={check} /></div>

						<IonGrid style={{background: "#D8E3F9",     flexGrow: 0,  width: "100%", borderRadius:10}}>
						<Row style={{fontWeight:"bold"}}>
								<IonCol style={{textAlign:"center"}}>Registro de {data?.tipo}</IonCol>
							</Row>
							<Row>
								<IonCol>Cantidad</IonCol>
								<Value><Currency amount={data?.cantidad} /></Value>
							</Row>
							<Row>
								<IonCol>Destinatario</IonCol>
								<Value>{data?.destinatario?.nombre}</Value>
							</Row>
							<Row>
								<IonCol>Fecha</IonCol>
								<Value><Date date={data?.fecha} /></Value>
							</Row>
						
							{data?.notas && <Row>
								<IonCol>Notas</IonCol>
								<Value>{data?.notas}</Value>
							</Row>}
							{data?.id_recurrencia && data?.recurrencia?.activo && <Row>
								<IonCol>Recurrencia</IonCol>
								<Value>Se repite cada {data?.recurrencia?.recurrencia}</Value>
							</Row>}
							{data?.archivo?.path && <Row>
								<IonCol>Imagen</IonCol>
								<Value><img src={process.env.REACT_APP_FILE_URL + data?.archivo?.path} /></Value>
							</Row>}
						</IonGrid>
					</div>
					<div style={{padding:20, width:"100%"}}>
						<IonButton expand="block" onClick={compartir}>Compartir</IonButton> 			
						<IonButton expand="block" color="danger" onClick={() => (removeOp(data?._id))}>Eliminar</IonButton> 			
					</div>
				
					
					{data?.id_recurrencia && data?.recurrencia?.activo && <>
						<IonButton style={{marginTop:20}} expand="block" color="danger" onClick={() => (removeRec(data?.id_recurrencia))}>Cancelar operación recurrente</IonButton> 			
					</>}
				</div>
		
				
			
				
				
			

				
        
      </IonContent>
			
    </IonPage>
  );
};
const options = [
	{value:false, label:"1 vez"},
	{value:"semanal", label:"Semanal"},
	{value:"quincenal", label:"Quincenal"},
	{value:"mensual", label:"Mensual"},
]
const Periodo = ({onChange, value}) => {
	return <div>
		<div>
			<CustomLabel>Periodo</CustomLabel>
		</div>
		<Select value={value} onChange={onChange} options={options} />
	</div>;
}
const Select: React.FC<any> = ({options, onChange, value}) => {
	return <div style={{display:"flex", justifyContent:"space-between" }}>
		{options.map(o=><Option {...o} onChange={onChange} selected={value===o.value} />)}
	</div>
}
const StyledOption = styled.div<any>`
	padding:10px 10px;
	border-radius:10px;
	background: ${props=>props.selected ? "var(--ion-color-primary)" : "var(--ion-color-secondary)"};
	color: ${props=>props.selected ? "var(--ion-color-secondary)" : "var(--ion-color-primary)"};
`
const Option: React.FC<any> = ({label, value, onChange, selected}) => {
	return <>
		<StyledOption selected={selected} onClick={()=>onChange(value)}>{label}</StyledOption>
	</>
}
const CustomInput: React.FC<any> = ({label, ...rest}) => {
	return <>
		<CustomLabel>{label}</CustomLabel>
		<IonInput {...rest} style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}}></IonInput>
	</>
}
const CustomLabel: React.FC<any> = ({children, ...rest}) => {
	return <>
		<IonText {...rest}>{children}</IonText>
	</>
}

const Destinatario = ({onChange, value}) => {
	const [focused, setFocused] = React.useState(false);
	const [searchString, setSearchString] = React.useState("");
	const {data} = useQuery("destinatarios",getDestinatarios);
	const filtered = React.useMemo(()=>(data || []).filter(d=>d.nombre.includes(searchString)), [data, searchString]);
	const onKey = (e) => {
		e.key==="Enter" ? setFocused(false) :  setFocused(true)
	}
	const onSearchChange = (e) => {
		
		setSearchString(e.target.value)
	}
	console.log({searchString, filtered})
	return <div>
		<CustomInput label="Para" value={value} onIonInput={onSearchChange} onKeyDown={onKey} onFocus={()=>setFocused(true)} onBlur={()=>setTimeout(()=>setFocused(false),100)}  onIonChange={e=>onChange(e.detail.value)}  placeholder="Fuente de ingreso"></CustomInput>
		{focused && <IonList>
			{filtered.map(o => <IonItem button onClick={()=>(onChange(o.nombre),setFocused(false))}>
				<IonLabel className="ion-text-wrap">
					<h2>{o.nombre}</h2>
				</IonLabel>
			</IonItem>)}	
    </IonList>}
	</div>
}
const MyDate = ({onChange}) => {
	const [isOpen, setOpen] = React.useState(false)
	return <>
		<CustomLabel>Seleccionar fecha</CustomLabel>
		<IonDatetime style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}} onIonChange={ev => onChange(ev.detail.value!)} placeholder="Seleccionar fecha" /> 
	</>
}
const File = ({onFile}) => {

	const subirEvidencia = async () => {
		const options: CameraOptions = {
			quality: 100,
			sourceType:0,
		}
		
		const pic = await Camera.getPicture(options);
		onFile(pic);
	}
	

	return <div>
		<CustomLabel>Añadir imagen</CustomLabel>
		<div>
			<IonButton  onClick={subirEvidencia}>Seleccionar</IonButton>
		</div>
	</div>
}

export default Home;
const Title = styled.div`
	font-size:1.3em;
`;