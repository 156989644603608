import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { home as homeIcon, settings as settingsIcon } from 'ionicons/icons';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Operaciones from "pages/Operaciones";
import Operacion from "pages/Operacion";
import DetalleOperacion from "pages/DetalleOperacion"
import Halajot from "pages/Halajot";
import Halaja from "pages/Halaja";
import Reporte from "pages/Reporte";
import Tutorial from "components/Tutorial";
import operacionesImg from "img/operaciones.png";
import infoImg from "img/info.png";
import reporteImg from "img/reporte.png";

import operaciones2 from "img/operaciones2.png";
import halajot2 from "img/halajot2.png";
import reporte2 from "img/reporte2.png";
import { pieChartOutline, informationCircleOutline, cashOutline, informationCircle } from 'ionicons/icons';


import useGlobalContext from "contexts/GlobalContext";

const AppTabs: React.FC = () => {
	const { user, statusUser } = useGlobalContext();
	const [tab, setTab] = React.useState("home");

	if (statusUser==="loading") {
    return <Redirect to="/loading" />;
  }
  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <IonTabs onIonTabsDidChange={a=>console.log({a})} >
      <IonRouterOutlet>
        <Route exact path="/app/operaciones">
          <Operaciones />
        </Route>
				
        <Route exact path="/app/halajot">
          <Halajot />
        </Route>
				<Route exact path="/app/halajot/:id">
          <Halaja />
        </Route>
				<Route exact path="/app/reporte">
          <Reporte />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/app/operaciones">
					<IonIcon icon={cashOutline}></IonIcon>
					{/* {tab==="home" && <img  style={{height:25, marginTop:7}} src={operacionesImg} />}
					{tab!=="home" && <img  style={{height:25, marginTop:7}} src={operaciones2} />} */}
          <IonLabel>Operaciones</IonLabel>
        </IonTabButton>
        <IonTabButton tab="halajot" href="/app/halajot">
				<IonIcon icon={informationCircleOutline}></IonIcon>

					{/* {tab==="halajot" && <img  style={{height:25, marginTop:7}} src={infoImg} />}
					{tab!=="halajot" && <img  style={{height:25, marginTop:7}} src={halajot2} />} */}
          <IonLabel>Halajot</IonLabel>
        </IonTabButton>
				<IonTabButton tab="reporte" href="/app/reporte">
					<IonIcon icon={pieChartOutline}></IonIcon>

					{/* {tab==="reporte" && <img  style={{height:25, marginTop:7}} src={reporteImg} />}
					{tab!=="reporte" && <img  style={{height:25, marginTop:7}} src={reporte2} />}           */}
					<IonLabel>Reporte</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;
