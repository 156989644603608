import queryClient from "./query";

import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer';


const axios = require("axios");

const token = window.localStorage.getItem(process.env.REACT_APP_APP_NAME);
// export const socket = io.connect("http://localhost:3000/consultas_online");
 


// export const socket = io("http://localhost:3000/consultas_online");
// export const baseUrl =  "http://localhost:3050";
export const baseUrl =  process.env.REACT_APP_API_URL;

let instance = axios.create({
	baseURL: baseUrl,//process.env.REACT_APP_API_URL,
	timeout: 120000,
});

let localInstance = axios.create({
	baseURL: "/halajot/",//process.env.REACT_APP_API_URL,
	timeout: 120000,
});
export function getFile(uri, params){
	return new Promise((resolve, reject)=>{
		console.log({instance});
		localInstance.get(uri, {params}).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}


export const getHeaders = ()=>{
	return {
		Authorization:  "Bearer "+window.localStorage.getItem(process.env.REACT_APP_APP_NAME)
	};
}
instance.interceptors.request.use(
	config=>{
		const t = window.localStorage.getItem(process.env.REACT_APP_APP_NAME);
		config.headers.Authorization = `Bearer ${t}`;
		return config;

	}
)
export function init(token){
	instance.defaults.headers["Authorization"] = `Bearer ${token}`;
	window.localStorage.setItem(process.env.REACT_APP_APP_NAME, token);
	queryClient.invalidateQueries();

}


export const Authorization = `Bearer ${token}`;
export function get(uri, params){
	return new Promise((resolve, reject)=>{
		console.log({instance});
		instance.get(uri, {params}).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}

export function post(uri, data, options){
	return new Promise((resolve, reject)=>{
		instance.post(uri, data, options).then(function (response) {
			console.log("RESOLVE")
			resolve(response.data);
		}).catch(error=>{
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function put(uri, data){
	return new Promise((resolve, reject)=>{
		instance.put(uri, data).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}
export function remove(uri, data){
	return new Promise((resolve, reject)=>{
		instance.delete(uri, data).then(function (response) {
			resolve(response.data);
		}).catch(function (error) {
			if (error?.response?.status === 408 || error?.code === "ECONNABORTED") {
				reject("Por favor revisa tu conexión a internet");
			}
			return reject({errmsg: error.response?.data.error || error.message, errcode:error?.response?.status});
		});
	});
}

export const track = console.log
export const resetearPassword = (data) => post("/reset-password",data);
export const enviarCodigo = (data) => post("/enviar-codigo-password", data);

export const removeOperacion = async (id) => {
	const r = await remove(`/operacion/${id}`)
	queryClient.invalidateQueries("operaciones");
	queryClient.invalidateQueries("maaser");
	return r;
}
export const removeRecurrencia = async (id) => {
	const r = await remove(`/recurrencia/${id}`)
	queryClient.invalidateQueries("operaciones");
	queryClient.invalidateQueries("maaser");
	return r;
}
export const getMaaser = (data) => get("/usuario/me/maaser");
export const login = (data) => post("/usuario-login",data, { withCredentials: true });
export const registro = (data) => post("/usuario-registro",data, { withCredentials: true });
export const updateUser = async (data) => {
	await put(`/usuario/me`,data);
	queryClient.invalidateQueries("user");

}
export const getUser = () => get(`/usuario/me`)
export const getOperaciones = () => get("/operacion")
export const getOperacion = ({queryKey}) => get(`/operacion/${queryKey[1]}`)
export const getDestinatarios = () => get("/destinatario")
export const getReporte =async ({queryKey}) => {
	const params = queryKey[1]
	console.log({params})
	const r = await get("/reporte", params)
	return r;
}
export const postOperacion = async ({data, file}) => {
	console.log({a:file, data, file})
	const r= await post(`/operacion`,data)
	console.log(r);
	if(file){
		await upload("archivo",[file], `${baseUrl}/operacion/${r.data._id}/files`);
	}
	
	queryClient.invalidateQueries("destinatarios");
	queryClient.invalidateQueries("operaciones");
	queryClient.invalidateQueries("maaser");
	console.log(r);

	return r;
}
export const updateOperacion = async (id, data) => {
	await put(`/operacion/${id}`, data)
	queryClient.invalidateQueries("operaciones");
}

export const logout = () => {
	console.log("LOGGING OUT")
	window.localStorage.removeItem(process.env.REACT_APP_APP_NAME);

	// setGlobalState("loggedIn",false)
	instance.defaults.headers["Authorization"] = null;

	queryClient.resetQueries()

	queryClient.removeQueries();
	queryClient.invalidateQueries();
	return post("/cliente-logout");
}


export const crudFunctions = (type, replacements) => {
	const tipo = type.url;
	return {
		getAll: ()=>{
			return get(`/${tipo}`);
		},
		getOne: (id)=>{
			return get(`/${tipo}/${id}`);
		},
		insert:async ({files, ...data})=>{
			const response = await post(`/${tipo}`, data);
			// return response;
			if(files){
				const r = await upload("archivo", files, "files");
				return r;
			}
			return response;
		},
		update:async (id, {files, ...data})=>{
			const r = await put(`/${tipo}/${id}`, data);
			return r;
			// if(files){
			// 	const r = await upload(`/${tipo}/${id}/files`, files);
			// 	return r;
			// }
		},
		trash:(id)=>{
			return put(`/${tipo}/${id}`, {_trash: true});
		},
		untrash:(id)=>{
			return put(`/${tipo}/${id}`, {_trash: false});
		},
		delete:(id)=>{
			return remove(`/${tipo}/${id}`);
		},
		...replacements
	};
};





	
const upload = async (key ,files, uploadPath) => {
	let options = {
		fileKey: key,
		fileName: 'name.jpg',
		headers: getHeaders()
	}
	const fileTransfer = FileTransfer.create();

	console.log({files})
	await Promise.all(files.map(file=>{
		console.log({p:  uploadPath, file})
		return fileTransfer.upload(file, uploadPath, options)
	})).catch(e=>{
		Promise.reject(e);
	});
	
	
}
	
export default {post, get, put, remove, init, upload};
