import React from "react";
import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { Redirect, Route } from 'react-router-dom';
import CustomInput from "components/CustomInput";
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonInput,
	IonButton,
	IonSpinner,
	IonChip
} from '@ionic/react';
import logo from "img/logo.png";
import { Link } from 'react-router-dom';

import './Home.css';
import {resetearPassword, enviarCodigo} from "api";
import {useMutation} from "react-query";
import useGlobalContext from "../contexts/GlobalContext";

interface Usuario {
  codigo: string;
  password: string;
  correo: string;
}

const Login: React.FC = () => {
	const [step, setStep] = React.useState<number>(1);
	const {status, error, mutate:reset} = useMutation<any,any, any>(enviarCodigo, {onSuccess:s=>setStep(2)});
	const {status:status2, error:error2, mutate:reset2} = useMutation<any,any, any>(resetearPassword, {onSuccess:s=>setStep(3)});
	const { setUser, user } = useGlobalContext()
  const [messages, setMessages] = useState<Message[]>([]);
  const [usuario, setUsuario] = useState<Usuario>({codigo:"",password:"", correo:""});

  useIonViewWillEnter(() => {
    const msgs = getMessages();
    setMessages(msgs);
  });



	if(user){
    return <Redirect to="/app/operaciones" />;
	}

	console.log({step})
  return (
    <IonPage id="home-page">
      
      <IonContent style={{textAlign:"center"}} fullscreen>

        <img src={logo} style={{marginTop:150, width:"60%"}} />
				<div style={{background:"#FFBD50", width:"100%", height: "60%", position:"absolute", bottom:0}}>
					<div style={{background:"#0061B0", width:"100%", height: 30}} />
					<div style={{padding:20, textAlign:"center"}}>
						
						{step===1 &&<>
							<CustomInput style={{marginBottom:20}} value={usuario.correo} placeholder="Ingresa tu correo" onIonChange={e => setUsuario(u=>({...u, correo: e.detail.value || ""}))}></CustomInput>
							{status!=="loading" && <IonButton style={{marginBottom:20}} onClick={()=>reset(usuario)}>Enviar codigo de recuperacion</IonButton>}
						</>}
						{step===2 &&<>
							<CustomInput style={{marginBottom:20}} value={usuario.codigo} placeholder="Ingresa el código que enviamos a tu correo" onIonChange={e => setUsuario(u=>({...u, codigo: e.detail.value || ""}))}></CustomInput>
							<CustomInput style={{marginBottom:20}} value={usuario.password} placeholder="Ingresa tu nueva contraseña" onIonChange={e => setUsuario(u=>({...u, password: e.detail.value || ""}))}></CustomInput>
							{status!=="loading" && <IonButton style={{marginBottom:20}} onClick={()=>reset2(usuario)}>Enviar codigo de recuperacion</IonButton>}
						</>}
						{step===3 &&<>
							Contraseña reestablecida exitosamente
							<Link to="/login"><IonButton fill="clear">Iniciar sesión</IonButton></Link>

						</>}
						
						{status==="loading" && <IonSpinner />}
						{status==="error" && <IonChip color="danger">{error?.errmsg}</IonChip>}
					</div>
					
				</div>
				
				

        
      </IonContent>
    </IonPage>
  );
};

export default Login;
