// import ViajeItem from '../components/Viajes/ViajeItem';
import React, { useState } from 'react';
// import { ViajeItemProp } from '../interfaces/Viajes';
import { logout, getReporte } from '../api';
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
	IonButtons,
	IonBackButton,
  useIonViewWillEnter,
	useIonViewDidEnter,
	IonDatetime,
	IonText,
	IonInput
} from '@ionic/react';
import './Home.css';
import {Currency, Date} from "components/Core";
import {
  IonItem,
  IonLabel,
  IonNote,
	IonButton,
	IonSpinner,
	useIonModal
  } from '@ionic/react';
import Tutorial from 'components/TutorialModal';
import {useQuery} from "react-query";
import Logo from "components/Logo";
import donativoImg from "img/donativo.png";
import ingresoImg from "img/ingreso.png";
import egresoImg from "img/egreso.png";
import Destinatario from "components/Destinatario";

const imgMap = {
	donativo: donativoImg,
	ingreso: ingresoImg,
	egreso: egresoImg,
}
const d = "a"
const Viajes: React.FC = () => {
	const [filters, setFilters] = React.useState<any>({});

	const {data, status} = useQuery(["reporte", filters], getReporte, {enabled: !!(filters.desde && filters.hasta)});
	console.log({data, aa:!!(filters.desde && filters.hasta), status})

  const [viajes, setViajes] = useState<ViajeItemProp[]>([]);

  useIonViewDidEnter(() => {
		console.log("VIAJES");
    loadViajes();
  });
	const loadViajes = async () => {
		// const v = await getViajes();
    // setViajes(v);
	}

  const refresh = async (e: CustomEvent) => {
		await loadViajes();
		e.detail.complete();

  };

	const closeTutorial = () =>{
		dismiss()
	}
	const [present, dismiss] = useIonModal(Tutorial, {
    tipo:"reporte",
		close: closeTutorial
  });


  return (
    <IonPage id="home-page">
      <IonHeader>
				<IonToolbar>
        	<Logo onClickTutorial={present} />

				</IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
				<IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
				<div style={{ columnGap: 10, display: "flex"}}>
					<div style={{ width:"50%"}}>
						Desde
						<IonDatetime style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}} onIonChange={ev => setFilters(f=>({...f, desde:ev.detail.value!}))} placeholder="Desde" /> 
					</div>
					<div style={{ width:"50%"}}>
						Hasta
						<IonDatetime style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}} onIonChange={ev => setFilters(f=>({...f, hasta:ev.detail.value!}))} placeholder="Hasta" /> 
					</div>
				</div>
				<Destinatario value={filters.destinatario} onChange={(v)=>setFilters(f=>({...f, destinatario:v }))} />

				
			
				{/* Fuente de origen o destino
				<IonInput style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}}></IonInput> */}
	
				{status=="idle" &&<>
					<div style={{marginTop:50, color:"var(--ion-color-primary", fontSize:"2em", textAlign:"center"}}>Selecciona algunas fechas para generar el reporte</div>
				</>}
				{status==="loading" && <IonSpinner />}

				{status=="success" && <div style={{marginTop:20}}>
					<Titulo tipo="ingreso">Ingresos</Titulo>
					<Lista data={data} tipo="ingreso" />
					<Titulo tipo="egreso">Egresos</Titulo>
					<Lista data={data} tipo="egreso" />
					<Titulo tipo="donativo">Donativos</Titulo>
					<Lista data={data} tipo="donativo" />
				</div>}
				

				{/* {!viajes?.length && <div>No hay viajes por el momento</div>}
        <IonList>
          {viajes.map(viaje => <ViajeItem key={viaje._id} {...viaje} />)}
					
        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

export default Viajes;

const Lista:React.FC<any> = ({data, tipo})=>{
	const filtered = React.useMemo(()=>(data || []).filter(d=>d._id.tipo===tipo), [data]);
	const total = React.useMemo(()=>filtered.reduce((acc, v)=>acc+v.total,0), [filtered]);
	console.log({data, tipo})
	return <IonList>
		{filtered.map(d=><IonItem>
			<IonText color={tipo}>{d._id?.destinatario}</IonText>
			<IonText color={tipo} slot="end"><Currency amount={d.total} /></IonText>
		</IonItem>)}
		<IonItem>
			<IonText style={{fontWeight:"bold"}} color={tipo}>Total</IonText>
			<IonText style={{fontWeight:"bold"}} color={tipo} slot="end"><Currency amount={total} /></IonText>
		</IonItem>
	</IonList>
}
const Titulo:React.FC<any> = ({children, tipo})=>{
	return <div style={{ display: "flex", alignItems: "center"}}>
		<img slot="start" style={{width:30}} src={imgMap[tipo]} />
		<IonText style={{    marginLeft: 10, fontWeight: "bold"}} color={tipo}>{children}</IonText>
	</div>
}

// import './MessageListItem.css';

interface ViajeItemProp {
		_id: string;
		fecha: string;
		origen: any;
		destino: any;
}

const ViajeItem: React.FC<ViajeItemProp> = ({ _id, origen, destino, fecha }) => {
  return (
    <IonItem routerLink={`/viajes/${_id}`} detail={false}>
      {/* <div slot="start" className="dot dot-unread"></div> */}
      <IonLabel className="ion-text-wrap">
				<span className="date">
            <IonNote><Date date={origen?.fecha} /></IonNote>
          </span>
        <h2>Origen: {origen?.direccion?.calle} {origen?.direccion?.numero}, {origen?.direccion?.ciudad}</h2>
        <h2>Destino: {destino?.direccion?.calle} {origen?.direccion?.numero}, {origen?.direccion?.ciudad}</h2>
        <p>
        </p>
      </IonLabel>
    </IonItem>
  );
};
