import React from "react";
import {
	IonInput,
	IonText
} from '@ionic/react';


const CustomInput: React.FC<any> = ({label, style, ...rest}) => {
	return <div style={style}>
		<CustomLabel>{label}</CustomLabel>
		<IonInput {...rest} style={{background: "var(--ion-color-secondary)", borderRadius: "10px"}}></IonInput>
	</div>
}
const CustomLabel: React.FC<any> = ({children, ...rest}) => {
	return <>
		<IonText {...rest}>{children}</IonText>
	</>
}
export default CustomInput;