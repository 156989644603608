import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSpinner, IonPage, IonContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Registro from './pages/Registro';
import Perfil from './pages/Perfil';
import Halaja from './pages/Halaja';
import Tabs from './Tabs';
import Operacion from './pages/Operacion';
import DetalleOperacion from './pages/DetalleOperacion';
import Contacto from './pages/Contacto';
import Logo from "img/logo.png";
import useGlobalContext from "contexts/GlobalContext";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import Forgot from "pages/Forgot";
/* Theme variables */
import './theme/variables.css';
import {GlobalContext} from "./contexts/GlobalContext";
import {getUser} from "api";
import { useQuery } from "react-query";
import Tutorial from "components/Tutorial";
const App: React.FC = () => {

	const [user, setUser] = React.useState();
	const [statusUser, setStatusUser] = React.useState("loading");
	// const [viajeActivo, setViajeActivo] = React.useState();
	React.useEffect(()=>{
		(async function(){
			const user = await getUser().catch(()=>setStatusUser("loaded"));
			setUser(user);
			setStatusUser("loaded");

		})();
	},[])



  return  <GlobalContext.Provider value={{user, setUser, statusUser}}>
		<IonApp>
		
			<IonReactRouter>
				<Switch>
						<Route exact path="/loading">
              <Loading />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
						<Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/registro">
              <Registro />
            </Route>
						<Route exact path="/perfil">
              <Perfil />
            </Route>
						<Route exact path="/contacto">
              <Contacto />
            </Route>
            <Route path="/app">
              <Tabs />
            </Route>
						<Route exact path="/operaciones/new/:tipo">
							<Operacion />
						</Route>
						<Route exact path="/detalle-operacion/:id">
							<DetalleOperacion />
						</Route>
						<Route exact path="/tutorial/:tipo">
							<Tutorial />
						</Route>
						<Route path="/halajot/:id">
              <Halaja />
            </Route>
						<Route path="/operaciones/new/:tipo">
              <Operacion />
            </Route>
            <Redirect exact path="/" to="/app" />
            <Route>
							<Redirect to="/app" />

            </Route>
          </Switch>				
			</IonReactRouter>
		</IonApp>
	</GlobalContext.Provider>
	
};

export default App;

const Loading  = ()=>{

	const { user, statusUser } = useGlobalContext()

	if(statusUser==="loaded" && user){
		return <Redirect to="/app/operaciones" />;
	}
	if(statusUser==="loaded" && !user){
		return <Redirect to="/login" />;
	}
	return <IonPage id="home-page">
      
		<IonContent style={{textAlign:"center"}} fullscreen>
			<div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", height:"100%",background:"white"}}>
				<img src={Logo} style={{marginBottom:30, width:"60%"}} />
				<IonSpinner />

			</div>
		</IonContent>
	</IonPage>
}
const PrivateRoute:React.FC<any> = ({component: Component, user, statusUser, path, exact}) => {
	return <Route
		path={path}
		exact={exact}
		render={(props) => {
			if(statusUser==="loaded" && !user){
				// return <Redirect to="/" />
			}
			return <Route><Component {...props} /></Route>
		}}
	/>
}
