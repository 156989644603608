import {
  IonItem,
  IonLabel,
  IonNote,
	IonIcon,
	IonToolbar,
	IonButtons,
	IonTitle
  } from '@ionic/react';
import logo from "img/logo.png";
import perfil from "img/perfil.png";
import { helpCircleOutline, callOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

import { Link } from 'react-router-dom';


const Logo: React.FC<any> = ({ hidePerfil, onClickTutorial }) => {
	const history = useHistory();
  return (<IonToolbar> 
					<IonButtons slot="start">
						{/* <IonIcon icon={helpCircleOutline} style={{fontSize:"3em"}}></IonIcon> */}

						<Link to="/perfil" style={{width:35}}><img src={perfil} /></Link>
						{/* <div style={{textAlign:"center", marginTop:20, marginBottom:10}}>
							{!hidePerfil && }
					</div> */}

					</IonButtons>
					<IonTitle style={{textAlign:"center"}}>
						<img src={logo} style={{maxWidth:"60%", maxHeight:45}} />

					</IonTitle>
					<IonButtons slot="end">
					{onClickTutorial && <IonIcon onClick={onClickTutorial} icon={helpCircleOutline} style={{fontSize:"2em"}}></IonIcon>}
					<IonIcon onClick={()=>history.push("/contacto")} icon={callOutline} style={{fontSize:"1.8em", marginLeft:5}}></IonIcon>
					</IonButtons>
	</IonToolbar>
	
   
		
		
		
  );
};

export default Logo;
