import React from 'react';
import { IonSlides, IonSlide, IonContent, IonPage, IonButton } from '@ionic/react';
import t1 from "img/tutorial/1.png";
import t2 from "img/tutorial/2.png";
import t3 from "img/tutorial/3.png";
import t4 from "img/tutorial/4.png";
import t5 from "img/tutorial/5.png";
import t6 from "img/tutorial/6.png";
import t7 from "img/tutorial/7.png";
import t8 from "img/tutorial/8.png";

// Optional parameters to pass to the swiper instance.
// See https://swiperjs.com/swiper-api for valid options.
const slideOpts = {
  initialSlide: 0,
  speed: 400
};
const tutoriales = {
	home:[
		{img:t1},
		{img:t2},
		{img:t3},
		{img:t4},
	],
	general:[
		{img:t1},
		{img:t2},
		{img:t3},
		{img:t4},
		{img:t8},
		{img:t5},
		{img:t6},
		{img:t7},
	],
	operacion:[
		{img:t8},
	],
	reporte:[
		{img:t6},
	],
}
const Tutorial: React.FC<any> = ({close, tipo}) => {
	const tutorial = React.useMemo(()=>tutoriales[tipo],[tipo]);
	const [isEnd, setIsEnd] = React.useState(()=>tutorial?.length===1);

	return  <IonPage id="home-page">
  <IonContent style={{background:"#FEF9EE"}}>
			{tutorial.map(slide=><>

				<div style={{display:"flex", flexDirection:"column", height:"100%", background:"#FEF9EE"}}>
					{/* <h1>{slide.texto}</h1> */}
					<img src={slide.img} />
					{isEnd && <IonButton onClick={close} fill="clear" style={{marginTop:10}} size="large">Cerrar</IonButton>}
				</div>
      </>)}
      
      
  </IonContent>
	</IonPage>
};
export default Tutorial