import React from "react";
import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import { Redirect, Route } from 'react-router-dom';
import CustomInput from "components/CustomInput";
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
	IonInput,
	IonButton,
	IonSpinner,
	IonChip
} from '@ionic/react';
import logo from "img/logo.png";
import { Link } from 'react-router-dom';

import './Home.css';
import {login, getUser, init} from "api";
import {useMutation} from "react-query";
import useGlobalContext from "../contexts/GlobalContext";

interface Usuario {
  correo: string;
  password: string;
}

const Login: React.FC = () => {
	const {status, error, mutate:doLogin} = useMutation<any,any, any>(login, {
		onSuccess:async s=>{
			init(s.token);
			const user = await getUser();
			setUser(user);
		}
	});
	const { setUser, user, statusUser } = useGlobalContext()
  const [messages, setMessages] = useState<Message[]>([]);
  const [usuario, setUsuario] = useState<Usuario>({correo:"",password:""});

  useIonViewWillEnter(() => {
    const msgs = getMessages();
    setMessages(msgs);
  });


	if(statusUser==="loading"){
		<Redirect to="/loading" />;
	}
	if(user){
    return <Redirect to="/app/operaciones" />;
	}

  return (
    <IonPage id="home-page">
      
      <IonContent style={{textAlign:"center"}} fullscreen>

        <img src={logo} style={{marginTop:150, width:"60%"}} />
				<div style={{background:"#FFBD50", width:"100%", height: "60%", position:"absolute", bottom:0}}>
					<div style={{background:"#0061B0", width:"100%", height: 30}} />
					<div style={{padding:20, textAlign:"center"}}>
						<CustomInput style={{marginBottom:20}} value={usuario.correo} placeholder="Usuario" onIonChange={e => setUsuario(u=>({...u, correo: e.detail.value || ""}))}></CustomInput>
						<CustomInput style={{marginBottom:20}} value={usuario.password} type="password" placeholder="Contraseña" onIonChange={e => setUsuario(u=>({...u, password: e.detail.value || ""}))}></CustomInput>
						{status==="loading" && <IonSpinner />}
						{status==="error" && <IonChip color="danger">{error?.errmsg}</IonChip>}
						{status!=="loading" && <IonButton style={{marginBottom:20}} onClick={()=>doLogin(usuario)}>Iniciar sesion</IonButton>}
						<Link to="/forgot"><IonButton fill="clear" >¿Olvidaste tu contraseña? Recuperala</IonButton></Link>
						<Link to="/registro"><IonButton fill="clear">¿No tienes cuenta? Registrate</IonButton></Link>
					</div>
					
				</div>
				
				

        
      </IonContent>
    </IonPage>
  );
};

export default Login;
